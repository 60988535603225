import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Box, Container, Stack, Button } from '@mui/material';
import { isNil, includes } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { TopupResultCard } from '../../components';
import { GOLDEN_RATIO } from '../../config';

import type { TopupStatus } from '../../components/topup_result_card';

const topUpStatusGql = gql(`
  query topUpStatus($referenceId: ID!) {
    topupStatus(referenceId: $referenceId) {
      success
      status
    }
  }
`);

const CheckOutCreditCardPendingPage = () => {
  const { state }: { state?: { otpFormHtml?: string; referenceId?: string } } = useLocation();
  const [topupStatus, setTopupStatus] = React.useState<TopupStatus>('pending');
  const navigate = useNavigate();
  const popupWindowRef = React.useRef<Window | null>(null);

  const [referenceId, setReferenceId] = React.useState<string | null>(null);
  const { data, loading, stopPolling } = useQuery(topUpStatusGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { referenceId },
    skip: isNil(referenceId),
    pollInterval: GOLDEN_RATIO * 5000,
  });

  React.useEffect(() => {
    if (loading) return;

    if (data?.topupStatus?.status) {
      setTopupStatus(data.topupStatus.status as TopupStatus);
      if (data.topupStatus.status === 'complete' || data.topupStatus.status === 'error') {
        stopPolling();
      }
    }
  }, [data?.topupStatus?.status, loading]);

  React.useEffect(() => {
    let popupInterval: NodeJS.Timeout | undefined;

    // If the popup is already open and not closed
    if (popupWindowRef.current && !popupWindowRef.current.closed) {
      // Check for URL change in the popup window
      popupInterval = setInterval(() => {
        if (popupWindowRef.current?.closed) {
          clearInterval(popupInterval);
          popupWindowRef.current = null;
          return;
        }

        try {
          const popupWindow = popupWindowRef.current;
          if (popupWindow && popupWindow.location) {
            const currentUrl = new URL(popupWindow.location.href);
            // If the URL has changed to /topup_result
            if (
              includes(new URL(currentUrl, window.location.origin).pathname, '/check_out_credit_card') ||
              includes(new URL(currentUrl, window.location.origin).pathname, '/topup_result')
            ) {
              setReferenceId(currentUrl.searchParams.get('referenceNo'));
              popupWindow.close();
              popupWindowRef.current = null;
              clearInterval(popupInterval);
            }
          }
        } catch (e) {
          clearInterval(popupInterval);
        }
      }, 500); // Check every 500ms
    } else if (state?.otpFormHtml) {
      const popup = window.open('about:blank', '', '_blank');
      if (popup) {
        popupWindowRef.current = popup;
        popup.document.write(state.otpFormHtml);
        popup.document.close();
      }
    } else {
      popupWindowRef.current = null;
    }

    if (state?.referenceId) {
      setReferenceId(state.referenceId);
    }

    // Cleanup function to clear interval on component unmount or dependencies change
    return () => {
      if (popupInterval) {
        clearInterval(popupInterval);
      }
    };
  }, [state?.otpFormHtml, state?.referenceId]);

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Stack alignItems="center">
        <Box sx={{ width: '75%' }}>
          <TopupResultCard topupStatus={topupStatus} />
          <Box sx={{ paddingRight: 3, paddingLeft: 3 }}>
            <Button variant="contained" fullWidth color="primary" sx={{ mt: 1 }} onClick={() => navigate('/')}>
              กลับหน้าหลัก
            </Button>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default CheckOutCreditCardPendingPage;
